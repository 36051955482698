@import "../../../src/scss/themes/basic/abstracts/custom_variables";
@import "../../../src/scss/themes/basic/abstracts/custom_mixins";
@import "_default/block_content";

.block_content {
    ul {
        @include custom-bullets($color-primary);
    }

    a {
        color: $color-primary;
    }
}
