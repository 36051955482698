//Theme variables
$theme-name: 'basic';

//Defaults
$default-size: 16px;

//Hard colors
$color-black: #000000;
$color-white: #FFFFFF;

//Colors
$color-primary: #E42320;
$color-primary-dark: #B81819;
$color-primary-hover: #FF0000;
$color-secondary: #100877;
$color-secondary-10: #E7E6F1;
$color-secondary-5: #F3F3F8;
$color-tertiary: #343435;

$color-error: #E91A1B;
$color-form-borders: #CCCCCC;
$color-menu-bg: #F0F0F0;

//Text colors
$color-text: $color-tertiary;

//Gradients
$gradient-red: linear-gradient(270deg, $color-primary-dark 0%, $color-primary-hover 100%);

//Font variables
$mainfont: 'NexaRegular';
$mainfont-bold: 'NexaBold';

//Font weights
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800;
$black: 900;

//Headings
$heading-h1-max: ceil(($default-size * 3.5)); // ~56px
$heading-h1-min: ceil(($default-size * 2.5)); // ~40px
$heading-h2-max: ceil(($default-size * 2.5)); // ~40px
$heading-h2-min: ceil(($default-size * 2)); // ~32px
$heading-h3-max: ceil(($default-size * 2)); // ~32px
$heading-h3-min: ceil(($default-size * 1.875)); // ~30px
$heading-h4: ceil(($default-size * 1.75)); // ~28px
$heading-h5: ceil(($default-size * 1.25)); // ~20px
$heading-h6: ceil(($default-size * 1.125)); // ~18px

//Text
$font-xl: ceil(($default-size * 1.25)); // ~20px
$font-l: ceil(($default-size * 1.125)); // ~18px
$font-m: ceil(($default-size * 1)); // ~16px
$font-s: ceil(($default-size * 0.875)); // ~14px
$font-xs: ceil(($default-size * 0.75)); // ~12px
$font-menu: ceil(($default-size * 1.25)); // ~20px
$font-form: ceil(($default-size * 0.75)); // ~12px

//Other text styles
$font-quote-max: ceil(($default-size * 2.5)); // ~40px
$font-quote-min: ceil(($default-size * 1.5625)); // ~25px

//Font settings
$text-settings: (
        'font-heading-h1': (
                font-family: $mainfont,
                font-size: clamp($heading-h1-min, 4vw, $heading-h1-max),
                font-weight: $regular,
                line-height: 100%,
        ),
        'font-heading-h2': (
                font-family: $mainfont,
                font-size: clamp($heading-h2-min, 4vw, $heading-h2-max),
                font-weight: $regular,
                line-height: 100%,
        ),
        'font-heading-h3': (
                font-family: $mainfont,
                font-size: clamp($heading-h3-min, 4vw, $heading-h3-max),
                font-weight: $regular,
                line-height: 110%,
        ),
        'font-heading-h4': (
                font-family: $mainfont-bold,
                font-size: $heading-h4,
                font-weight: $bold,
                line-height: 120%,
        ),
        'font-heading-h5': (
                font-family: $mainfont-bold,
                font-size: $heading-h5,
                font-weight: $bold,
                line-height: 120%,
        ),
        'font-heading-h6': (
                font-family: $mainfont-bold,
                font-size: $heading-h6,
                font-weight: $bold,
                line-height: 120%,
        ),
        'font-text-xl': (
                font-family: $mainfont,
                font-size: $font-xl,
                line-height: 150%,
        ),
        'font-text-l': (
                font-family: $mainfont,
                font-size: $font-l,
                line-height: 150%,
        ),
        'font-text-m': (
                font-family: $mainfont,
                font-size: $font-m,
                line-height: 150%,
        ),
        'font-text-s': (
                font-family: $mainfont,
                font-size: $font-s,
                line-height: 150%,
        ),
        'font-quote': (
                font-family: $mainfont,
                font-size: clamp($font-quote-min, 4vw, $font-quote-max),
                line-height: 110%,
        )
);

;@import "sass-embedded-legacy-load-done:13";