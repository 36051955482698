@import "../../../../src/scss/vendor/foundation/foundation_settings";
@import "../../../../src/scss/abstracts/custom_variables";
@import "../../../../src/scss/abstracts/custom_mixins";

.block_content {
    padding: 48px 0;

    @include breakpoint(xlarge) {
        padding: 80px 0;
    }

    blockquote {
        padding: 0;

        @include breakpoint(xlarge) {
            width: calc(100% + 60px);
            margin-left: -30px;
            text-align: center;
        }
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: 40px;
    }

    h1,
    h2 {
        margin-bottom: 40px;
    }

    h3 {
        margin-bottom: 32px;
    }

    h4 {
        margin-bottom: 24px;
    }

    h5 {
        margin-bottom: 16px;
    }

    h6 {
        margin-bottom: 12px;
    }

    .button {
        @include breakpoint(medium down) {
            &:not(.button--tertiary) {
                width: 100%;
                margin: 8px 0;
            }

            &.button--tertiary {
                left: 50%;
                transform: translateX(-50%);
            }
        }

        @include breakpoint(large) {
            margin-right: 16px;
        }
    }
}

;@import "sass-embedded-legacy-load-done:100";